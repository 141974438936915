const lotusFall2023option1Bundle = [
  {
    link: "https://playyon.com/606-academy/programs/23-enrichment-session-1245-to-/",
    name: "23 Enrichment Sessions",
    date: "1:15-4:00 PM",
  },
]

const lotusFall2023option2Bundle = [
  {
    link: "https://playyon.com/606-academy/programs/23-enrichment-session-1245-to-1/",
    name: "23 Enrichment Sessions",
    date: "1:15-2:45 PM",
  },
]

const lotusFall2023option1IndividualSessions = [
  {
    link: "https://playyon.com/606-academy/programs/oct-16th-to-nov-20th-5-enrichm/",
    name: "**5 Sessions October 16th",
    date: "November 20th $96**",
  },
  {
    link: "https://playyon.com/606-academy/programs/dec-4th-to-jan-22nd-4-enrichme/",
    name: "**4 Sessions December 4th",
    date: "January 22nd $76.70**",
  },
  {
    link: "https://playyon.com/606-academy/programs/jan-29th-to-march-5th-6-enrich/",
    name: "January 29th",
    date: "March 5th",
  },
  {
    link: "https://playyon.com/606-academy/programs/mar-12th-to-april-16th-5-enric/",
    name: "**5 Sessions March 12th",
    date: "April 16th $96**",
  },
  {
    link: "https://playyon.com/606-academy/programs/may-7th-to-may-21st-3-enrichme/",
    name: "** $57.50 May 7th",
    date: "May 21st 3 Sessions**",
  },
]

const lotusFall2023option2IndividualSessions = [
  {
    link: "https://playyon.com/606-academy/programs/oct-16th-to-nov-20th-6-enrichment-sessions-1245-2/",
    name: "** 5 Sessions October 16th",
    date: "November 20th $60 **",
  },
  {
    link: "https://playyon.com/606-academy/programs/dec-4th-to-jan-22nd-4-enrichme-1/",
    name: "**4 Sessions December 4th",
    date: "January 22nd $48**",
  },
  {
    link: "https://playyon.com/606-academy/programs/jan-29th-to-mar-5th-6-enrichment-sessions-1245-to-/",
    name: "January 29th",
    date: "March 5th",
  },
  {
    link: "https://playyon.com/606-academy/programs/mar-12th-to-apr-16th-5-enrichm/",
    name: "**5 Sessions March 12th",
    date: "April 16th $60**",
  },
  {
    link: "https://playyon.com/606-academy/programs/may-7th-to-may-21st-3-enrichme-1/",
    name: "$36 *** May 7th",
    date: "May 21st *** 3 Sessions",
  },
]


const lotusFall2023AInfo = [
  "Registration closes one week prior to start date",
  "No class held on 10/23, 11/27, 12/11, 12/18, 12/25. 1/1, 3/26 4/23, and 4/30;",
  "Registration afterdeadline subject to late registration fees.",
  "ONLY Lotus School students are eligible to sign up for this enrichment program",
  "Individual day registrations are available upon request.",
  "Individual day registration starts at $35 from 1:15 to 2:45PM; $50 from 1:15 to 4:00PM",
  "Held on early release Wednesdays",
  "Bring a basketball and a water bottle.",
  "K to 4th Grade welcome",
]

const lotusFall2023Drills = [
  "Triple Threat",
  "Stationary Ball Handling",
  "Ball Handling",
  "Attacking the Basket",
  "Layups",
  "Form Shooting",
  "Footwork",
  "Transition",
  "Three Man Weave",
  "Passing",
  "Passing Off the Dribble",
  "Defense",
  "Rebounding",
  "and more!"
]



export {lotusFall2023Drills, lotusFall2023AInfo, lotusFall2023option1IndividualSessions, lotusFall2023option2IndividualSessions, lotusFall2023option1Bundle, lotusFall2023option2Bundle}