const trainingOption1Bundle = [
  {
    link: "https://playyon.com/606-academy/programs/training-october-29-to-nov-28-tues-wed-thurs/",
    name: "3 Day Bundle T/W/Th",
    date: "7:30-9:00 PM",
  },
]

const trainingOption2Bundle = [
  {
    link: "https://playyon.com/606-academy/programs/training-october-29-to-nov-28-tues-wed-thurs/",
    name: "2 Day Bundle T/W/Th",
    date: "7:30PM to 9:00 PM",
  },
]

const tuesdayTrainingOption = [
  {
    link: "https://playyon.com/606-academy/programs/training-october-29-to-nov-28-tues-wed-thurs/",
    name: "* 4 Classes October 29th",
    date: "November 26th $80 *",
  },
]


const wednesdayTrainingOption = [
  {
    link: "https://playyon.com/606-academy/programs/training-october-29-to-nov-28-tues-wed-thurs/",
    name: "October 30th",
    date: "November 27th",
  },
]

const thursdayTrainingOption = [
  {
    link: "https://playyon.com/606-academy/programs/training-october-29-to-nov-28-tues-wed-thurs/",
    name: "October 31st",
    date: "November 28th",
  },
]


const trainingInfo = [
  "* No training on November 5th Election Day *",
  "Training held at Lotus School on Nov 19th, 20th, and 21st",
  "Registration closes one week prior to start date",
  "Individual day registration starts at $45 and available upon request",
  "Bring a basketball and a water bottle.",
  "3rd Grade to 11th Grade",
]

const trainingDrills = [
  "Triple Threat",
  "Stationary Ball Handling",
  "Ball Handling",
  "Attacking the Basket",
  "Layups",
  "Form Shooting",
  "Footwork",
  "Transition",
  "Three Man Weave",
  "Passing",
  "Passing Off the Dribble",
  "Defense",
  "Rebounding",
  "and more!"
]



export {trainingDrills, trainingInfo, tuesdayTrainingOption, wednesdayTrainingOption, thursdayTrainingOption,trainingOption1Bundle, trainingOption2Bundle}